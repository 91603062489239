import { Dialog, DialogTitle, Typography, IconButton, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { GraphItemType } from './graph/graph-interfaces';
import { QueriesStatic } from 'graphql/queries-static';
import { ITenant } from 'interfaces/tenant';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { ChangeListModel } from 'models/change/change-list-model';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ChangeModel } from 'models/change/change';
import AtByView from './AtBy';
import ReactDiffViewer from 'react-diff-viewer';
import { LogEventsListModel } from 'models/change/log-events-list-model copy';
import { LogEventModel } from 'models/change/logEvent';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import { start } from 'repl';
import { Utilities } from 'utilities/utilities';

interface Props {
  name: string,
  onCloseDialog: () => void,
  tenant: ITenant,
  open: boolean
}

const ListLogEventsView: React.FC<Props> = (props) => {
  const [logEvents, setLogEvents] = useState<Array<LogEventModel>>(new Array<LogEventModel>());
  const [nextToken, setNextToken] = useState('');
  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState(() => { const t = new Date(); t.setHours(0, 0, 0, 0); return t; });
  const [endDate, setEndDate] = useState(() => { const t = new Date(); t.setHours(23, 59, 59, 999); return t; });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLogEvents = async () => {
      if (props.name && props.tenant && props.tenant.name !== 'DEFAULT_TENANT') {
        await getLogEvents(props.tenant, startDate, endDate, false);
      }
    }
    fetchLogEvents();
  }, [props.name, props.tenant]);

  const getLogEvents = async (tnt: ITenant, sDate: Date, eDate: Date, append: boolean) => {
    setLoading(true);
    const params = {
      tenant: tnt.name,
      name: props.name
    }
    // iso 8601 utc - AWSDateTime format
    // see https://docs.aws.amazon.com/appsync/latest/devguide/scalars.html
    let token = nextToken;
    if (!append) {
      token = '';
    }

    let query = QueriesStatic.listLogEvents(eDate.toISOString(), token, 25, filter, sDate.toISOString());
    if (query) {
      const p = await GraphQLHelper.execute<LogEventsListModel>(query, params, LogEventsListModel);
      if (!p.error) {
        const logs = p.result as LogEventsListModel;
        if (logs.lastEvaluatedKey) {
          setNextToken(logs.lastEvaluatedKey);
        }
        let all = logs.logs;
        if (append) {
          all = logEvents.concat(logs.logs);
        }
        setLogEvents(all);
      }
    }
    setLoading(false);
  }

  const handleStartDateChange = (date: any) => {
    if (date) {
      const t = new Date(date);
      t.setHours(0, 0, 0, 0);
      setStartDate(t);
      //getLogEvents(props.tenant, t, endDate, false);
    }
  }

  const handleEndDateChange = (date: any) => {
    if (date) {
      const t = new Date(date);
      t.setHours(23, 59, 59, 999);
      setEndDate(t);
      //getLogEvents(props.tenant, startDate, t, false);
    }
  }

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value);
  }

  return (
    <>
      <Dialog open={props.open} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg" >
        <DialogTitle style={{ height: '0px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>Log Events For '{props.name}'</div>
            <div style={{ marginLeft: 'auto' }}>
              <Typography align="right">
                <IconButton onClick={() => { props.onCloseDialog(); }}>
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <div style={{ marginTop: '20px' }}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Start Date"
                format="MM/DD/YYYY"
                value={startDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => handleStartDateChange(date)}
              />
              <KeyboardDatePicker
                autoOk
                style={{ marginLeft: '10px' }}
                variant="inline"
                inputVariant="outlined"
                label="End Date"
                format="MM/DD/YYYY"
                value={endDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => handleEndDateChange(date)}
              />
              <TextField
                variant="outlined"
                autoFocus
                id="appName"
                style={{ marginLeft: '10px', width: '300px' }}
                label="Filter Expression"
                onChange={handleFilterChange}
              />
              <Button
                disabled={loading}
                onClick={() => getLogEvents(props.tenant, startDate, endDate, false)}
                variant="contained"
                style={{ marginLeft: '10px', height: '55px', width: '200px' }}
                color="primary">
                  Search
              </Button>
            </div>
          </MuiPickersUtilsProvider>
          <div style={{ height: '500px' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Timestamp</TableCell>
                    <TableCell align="left">Level</TableCell>
                    <TableCell align="left">Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logEvents && logEvents.map((o, index) => {
                    return (
                      <TableRow key={`${o.timestamp}-${index}`}>
                        <TableCell style={{ width: '250px' }}>{Utilities.toLocaleString(o.timestamp)}</TableCell>
                        <TableCell style={{ width: '100px' }}>{o.logLevel}</TableCell>
                        <TableCell style={{ width: '600px' }}>{o.message}</TableCell>
                      </TableRow>)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Button disabled={loading} onClick={() => getLogEvents(props.tenant, startDate, endDate, true)} variant="contained" color="primary">Load More</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ListLogEventsView;
