import { MessageTypeCache } from "cache/MessageTypeCache";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { MountRequirement } from "./mount-requirement";
import { ManagedNodeTypeModel } from "./managed-node-type";
import { PortRequirement } from "./port-requirement";
import { Protocol } from "enumerations/protocol";

export class ManagedNodeTypeListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  managedNodes: Array<ManagedNodeTypeModel> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("managed-node-type-list", data);
    if (data && data.data && data.data.ListManagedNodeTypes && data.data.ListManagedNodeTypes.echos) {
      const dt = data.data.ListManagedNodeTypes.echos;
      this.lastEvaluatedKey = data.data.ListManagedNodeTypes.lastEvaluatedKey;
      const messageTypes = await new MessageTypeCache().getMessageTypes(tenant);
      const nodes = dt.map((o: {
        sendMessageType: { name: string; };
        receiveMessageType: { name: string; };
        readme: string,
        configTemplate: string;
        created: { at: string; by: string; };
        description: string;
        imageUri: string;
        lastModified: { at: string; by: string; };
        name: string;
        tenant: { name: string; description: string; region: string; active: boolean; };
        mountRequirements: [{ description: string; source: string; target: string; }];
        portRequirements: [{ containerPort: number, description: string, protocol: Protocol, hostAddress: string, hostPort: number }];
        system: boolean;
        __typename: string;
      }) => {
        let sendMessageType = new MessageTypeModel();
        let receiveMessageType = new MessageTypeModel();
        if (o.sendMessageType) {
          sendMessageType = messageTypes.find(t => t.name === o.sendMessageType.name) as MessageTypeModel;
        }
        if (o.receiveMessageType) {
          receiveMessageType = messageTypes.find(t => t.name === o.receiveMessageType.name) as MessageTypeModel;
        }

        let tnt = TenantModel.create('', '', '', false);
        if (o.tenant) {
          tnt = TenantModel.create(o.tenant.name, o.tenant.description, o.tenant.region, o.tenant.active);
        }

        let mountRequirements = new Array<MountRequirement>();

        if (o.mountRequirements) {
          mountRequirements = o.mountRequirements.map(k => {
            return new MountRequirement(k.description, k.source, k.target);
          })
        }

        let portRequirements = new Array<PortRequirement>();
        if (o.portRequirements) {
          portRequirements = o.portRequirements.map(k => {
            return new PortRequirement(k.containerPort, k.description, k.protocol);
          })
        }

        return ManagedNodeTypeModel.create(
          o.configTemplate,
          new AtByModel(o.created.at, o.created.by),
          o.description,
          mountRequirements,
          o.imageUri,
          new AtByModel(o.lastModified.at, o.lastModified.by),
          o.name,
          portRequirements,
          o.readme,
          tnt,
          o.system,
          o.__typename,
          receiveMessageType,
          sendMessageType
          );
      });
      this.managedNodes = nodes;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.ListManagedNodeTypes, && data.data.ListManagedNodeTypes.echos');
    }
  }
}
