export interface ITenant {
	name: string,
  description: string,
	region: string,
  active: boolean
}

export const NoTenant: ITenant = {
  name: 'DEFAULT_TENANT',
  description: 'DEFAULT_TENANT',
  region: 'none',
  active: false
}