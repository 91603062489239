import { ApiUserRole } from "enumerations/api-user-role";
import { IApiUser } from "interfaces/api-user";
import { GraphQLData } from "interfaces/graphql-data";
import { ApiUserModel } from "./api-user-model";
import { AtByModel } from "../at-by-model";
import { TenantModel } from "../tenant/tenant-model";
import { ITenant } from "interfaces/tenant";
import { CredentialsModel } from "models/credentials-model";

export class ApiUsersModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  apiUsers: Array<IApiUser> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("api-users-model", data.data);
    this.apiUsers = data.data.ListApiUsers.echos.map((o: { 
      credentials: { clientId: string, password: string, username: string, userPoolId: string},
      created: { at: string, by: string},
      username: string,
      lastModified: { at: string, by: string},
      description: string,
      role: ApiUserRole,
      tenant: { name: string; description: string; region: string; active: boolean };
      appsyncEndpoint: string; }) => {
      const tenant = TenantModel.create(o.tenant.name, o.tenant.description, o.tenant.region, o.tenant.active);
      const apiUserModel = ApiUserModel.create(
        CredentialsModel.create(o.credentials.clientId, o.credentials.password, o.credentials.username, o.credentials.userPoolId),
        new AtByModel(o.created.at, o.created.by), 
        new AtByModel(o.lastModified.at, o.lastModified.by), 
        o.username,
        o.description, 
        o.role, 
        tenant,
        o.appsyncEndpoint);
      console.log("apiUserModel", apiUserModel);
      console.log("tenant", tenant);
      return apiUserModel;
    });
  }
}
