import { UserRole } from "enumerations/user-role";
import { UserStatus } from "enumerations/user-status";
import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { ITenantUser } from "interfaces/tenant-user";
import { AtByModel } from "../at-by-model";
import { TenantModel } from "../tenant/tenant-model";

export class TenantUserModel implements ITenantUser, GraphQLData {
  lastEvaluatedKey: string = '';
  activeAt: string = '';
  created: IAtBy = new AtByModel('', '');
  email: string = '';
  firstName: string = '';
  invited: IAtBy = new AtByModel('', '');
  lastModified: IAtBy = new AtByModel('', '');
  lastName: string = '';
  role: UserRole = UserRole.read_only;
  status: UserStatus = UserStatus.inactive;
  tenant: ITenant = TenantModel.create('', '', '', false);

  public static create(
    activeAt: string,
    created: AtByModel,
    email: string,
    firstName: string,
    invited: AtByModel,
    lastModified: AtByModel,
    lastName: string,
    role: UserRole,
    status: UserStatus,
    tenant: TenantModel) : TenantUserModel {
    const t = new TenantUserModel();
    t.activeAt = activeAt; 
    t.created = created;
    t.email = email;
    t.firstName = firstName;
    t.invited = invited;
    t.lastModified = lastModified;
    t.lastName = lastName;
    t.role = role;
    t.status = status;
    t.tenant = tenant;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("tenant-user-model", data);
    if (data && data.data && (data.data.GetTenantUser || (data.data.GetTenant && data.data.GetTenant.AddUser))) {
      let dt;

      if (data.data.GetTenantUser) {
        dt = data.data.GetTenantUser;
        Object.assign(this, dt);
      } else {
        dt = data.data.GetTenant.AddUser;
      }

      this.activeAt = dt.activeAt;
      this.created = dt.created;
      this.email = dt.email;
      this.firstName = dt.firstName;
      // dt.graphLayouts
      this.invited = dt.invited;
      this.lastModified = dt.lastModified;
      this.lastName = dt.lastName;
      this.role = dt.role;
      this.status = dt.status;
      this.tenant = dt.tenant;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenantUser');
    }
  }
}