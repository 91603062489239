import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "../at-by-model";
import { TenantModel } from "../tenant/tenant-model";

export class MessageTypeModel implements IMessageType, GraphQLData {
  lastEvaluatedKey: string = '';
  scannedCount: number = 0;
  auditor: string = '';
  bitmapperTemplate: string = '';
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = '';
  readme: string = '';
  requirements: string[] = [];
  sampleMessage: string = '';
  system: boolean = false;
  tenant: ITenant = new TenantModel();
  processorTemplate: string = '';

  public static create(
    auditor: string,
    bitmapperTemplate: string,
    created: AtByModel, 
    description: string,
    lastModified: AtByModel,
    name: string,
    readme: string,
    requirements: string[],
    sampleMessage: string,
    system: boolean,
    tenant: ITenant,
    processorTemplate: string) : MessageTypeModel {
    const t = new MessageTypeModel();
    t.auditor = auditor;
    t.bitmapperTemplate = bitmapperTemplate;
    t.created = created;
    t.description = description ? description : '';
    t.lastModified = lastModified;
    t.name = name;
    t.readme = readme ? readme : '';
    t.requirements = requirements;
    t.sampleMessage = sampleMessage;
    t.system = system;
    t.tenant = tenant;
    t.processorTemplate = processorTemplate;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("messagetype-model", data);
    let md = null;
    if (data && data.data && data.data.CreateMessageType) {
      md = data.data.CreateMessageType
    } else if (data && data.data && data.data.GetMessageType && data.data.GetMessageType.Update) {
      md = data.data.GetMessageType.Update
    } else if (data && data.data && data.data.GetMessageType && data.data.GetMessageType) {
      md = data.data.GetMessageType
    } else {
      throw new Error('data, data.data or data.data.CreateMessageType, GetMessageType, GetMessageType.Update empty');
    }

    if (md) {
      this.auditor = md.auditor;
      this.bitmapperTemplate = md.bitmapperTemplate;
      this.created = new AtByModel(md.created.at, md.created.by);
      this.description = md.description;
      this.lastModified = new AtByModel(md.lastModified.at, md.lastModified.by);
      this.name = md.name;
      this.readme = md.readme;
      this.requirements = md.requirements;
      this.sampleMessage = md.sampleMessage;
      this.system = md.system;
      this.tenant = TenantModel.create(md.tenant.name, md.tenant.description, md.tenant.region, md.tenant.active);
      this.processorTemplate = md.processorTemplate;
    }
  }
}