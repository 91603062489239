import { UserRole } from "enumerations/user-role";
import { UserStatus } from "enumerations/user-status";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { ITenantUser } from "interfaces/tenant-user";
import { AtByModel } from "../at-by-model";
import { TenantModel } from "../tenant/tenant-model";
import { TenantUserModel } from "./tenant-user-model";

export class TenantUsersModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  tenantUsers: Array<ITenantUser> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log("tenant-users-model", data.data);
    this.tenantUsers = data.data.ListTenantUsers.echos.map((o: { 
      activeAt: string, 
      created: { at: string, by: string},
      email: string,
      firstName: string,
      invited: AtByModel,
      invitationToken: string,
      lastModified: { at: string, by: string},
      lastName: string,
      role: UserRole,
      status: UserStatus,
      tenant: { name: string; description: string; region: string; active: boolean; }; }) => {
      const tenant = TenantModel.create(o.tenant.name, o.tenant.description, o.tenant.region, o.tenant.active);
      const userModel = TenantUserModel.create(
        o.activeAt, 
        new AtByModel(o.created.at, o.created.by), 
        o.email, 
        o.firstName, 
        new AtByModel(o.invited.at, o.invited.by), 
        new AtByModel(o.lastModified.at, o.lastModified.by), 
        o.lastName, 
        o.role, 
        o.status, 
        tenant);
      return userModel;
    });
  }
}
