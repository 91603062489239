import { AppTypeName } from "enumerations/apptype-name";
import { IApp } from "interfaces/app";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { ManagedAppModel } from "models/managed/managed-app";
import { ManagedInstanceModel } from "models/managed/managed-instance";
import { TenantModel } from "models/tenant/tenant-model";
import { CrossAccountAppModel } from "./cross-account-app";
import { CrossTenantReceivingAppModel } from "./cross-tenant-receiving-app";
import { CrossTenantSendingAppModel } from "./cross-tenant-sending-app";
import { ExternalAppModel } from "./external-app";
import { NotImplementedAppModel } from "./not-implemented-app";

export class AppListModel implements GraphQLData {

  lastEvaluatedKey: string = '';
  applications: Array<IApp> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.ListApps) {
      const apps = data.data.ListApps;
      console.log("apps model", data.data.ListApps);
      this.applications = apps.echos.map((o: { 
        tenant: { name: string; description: string; region: string; active: boolean; }; 
        __typename: string; 
        name: string; 
        description: string;
        tableAccess: boolean; 
        account: string; 
        appsyncEndpoint: string, 
        managedInstances: Array<ManagedInstanceModel>, 
        receivingApp: string, 
        receivingTenant: string,
        sendingApp: string,
        sendingTenant: string }) => {
        const tenant = TenantModel.create(o.tenant.name, o.tenant.description, o.tenant.region, o.tenant.active);
        if (o.__typename === AppTypeName.CrossTenantReceivingApp) {
          return CrossTenantReceivingAppModel.create(o.name, tenant, o.__typename, o.sendingApp, o.sendingTenant);
        } else if (o.__typename === AppTypeName.CrossTenantSendingApp) {
          return CrossTenantSendingAppModel.create(o.name, o.description, tenant, o.__typename, o.receivingApp, o.receivingTenant);
        } else if (o.__typename === AppTypeName.ExternalApp) {
          return ExternalAppModel.create(o.name, tenant, o.__typename, o.tableAccess ? true : false, o.appsyncEndpoint, o.description);
        } else if (o.__typename === AppTypeName.ManagedApp) {
          return ManagedAppModel.create(o.name, o.description, tenant, o.__typename, o.tableAccess ? true : false, o.managedInstances);
        } else if (o.__typename === AppTypeName.CrossAccountApp) {
          return CrossAccountAppModel.create(o.name, o.description, tenant, o.__typename, o.tableAccess ? true : false, o.account, o.appsyncEndpoint);
        }
        return NotImplementedAppModel.create(o.name, tenant, o.__typename);
      })
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.ListApps');
    }
  }
}