import Tenants from 'components/tenants/Tenants';
import Applications from 'components/Applications';
import TenantDetails from 'components/tenants/TenantDetails';
import TenantSettings from 'components/tenants/TenantSettings';
import { IMenuRoute } from 'interfaces/menu-route';

const routes : Array<IMenuRoute> = [
  {
    path: "/tenants",
    name: "Tenants",
    component: Tenants,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    display: true
  },
  {
    path: "/tenantdetails",
    name: "Tenant Details",
    component: TenantDetails,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    display: false
  },
  {
    path: "/tenantsettings",
    name: "Tenant Settings",
    component: TenantSettings,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    display: false
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    display: true
  }
];

export default routes;
