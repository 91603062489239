import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';
import TenantListContext from 'contexts/TenantListContext';
import { ITenant } from 'interfaces/tenant';
import { SortTenants } from 'models/tenant/tenant-list-model';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { TenantModel } from 'models/tenant/tenant-model';
import { MutationsStatic } from 'graphql/mutations-static';
import { TenantRegionCache } from 'cache/TenantRegionCache';

interface Props {
  show: boolean;
  closed: () => void;
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TenantAddDialog: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(props.show);
  const [tenantName, setTenantName] = useState('');
  const [description, setDescription] = useState('');
  const [tenantValid, setTenantValid] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const [infoSaving, setInfoSaving] = useState('');
  const [successSaving, setSuccessSaving] = useState('');
  const { tenantList, setTenantList } = useContext(TenantListContext);
  const [isLoading, setIsLoading] = useState(false);
  const [region, setRegion] = useState('');
  const [active, setActive] = useState(false);
  const [regions, setRegions] = useState<Array<string>>(new Array<string>());

  const getRegions = async () => {
    setRegions(await (await new TenantRegionCache().getRegions()).sort());
  }

  useEffect(() => setOpen(props.show), [props.show])

  useEffect(() => { 
    setErrorSaving(''); 
    setInfoSaving(''); 
    setSuccessSaving('');
    getRegions();
  }, [props.show])

  const handleClose = () => {
    setOpen(false);
    props.closed();
    setTenantName('');
    setTenantValid(false);
  };

  const handleAddTenant = async () => {
    if (tenantValid) {
      try {
        setIsLoading(true);
        setErrorSaving('');
        setInfoSaving('');
        setSuccessSaving('');
        // .. see if tenant already exists...
        const found = tenantList.find(o => o.name === tenantName);
        if (found) {
          setInfoSaving(`Tenant ${found.name} already exists.`)
          return;
        }
        console.log(`Add tentant ${tenantName}`);
        const tenant = {
          name: tenantName,
          region: region,
          description: description,
          billingInfo: '{}'
        }
        setIsLoading(true);
        const p = await GraphQLHelper.execute<TenantModel>(MutationsStatic.createTenant, tenant, TenantModel);
        setIsLoading(false);
        if (p.error) {
          setErrorSaving(JSON.stringify(p.errorMessage));
          console.log('Can\'t create tenant', p.errorMessage);
          return;
        }
        const createdTenant = p.result as TenantModel;
        console.log('Created tenant: ', createdTenant.name);
        setSuccessSaving(`Tenant ${tenantName} created.`);
        const newTenant: ITenant = {
          name: tenantName,
          description: description,
          region: region,
          active: active
        }
        let tntList = [...tenantList, newTenant];
        tntList = tntList.sort(SortTenants);
        setTenantList(tntList);
      } catch (err) {
        setErrorSaving(JSON.stringify(err));
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleOnChange = (event: any) => {
    setTenantName(event.target.value);
    setTenantValid(event.target.value.match(/^[A-Za-z0-9_\-]{8,80}$/));
  }

  const handleSetRegion = (event: any) => {
    setRegion(event.target.value);
    setTenantValid((event.target.value === "" || !tenantValid) ? false : true);
  }

  const handleDescription = (event: any) => {
    setDescription(event.target.value);
  }

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">Add Tenant</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the name of the new tenant. After creating a tenant please log in and logout to get access to the newly created resource.
        </DialogContentText>
        <TextField
          error={!tenantValid}
          helperText="Name must be atleast 8 characters in length and may contain special characters (- or _) ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="tentantName"
          label="Tenant name"
          fullWidth
          onChange={handleOnChange}
          disabled={((successSaving && setSuccessSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          helperText="A human-readable description"
          variant="outlined"
          margin="dense"
          id="description"
          label="Description"
          fullWidth
          onChange={handleDescription}
          disabled={((successSaving && setSuccessSaving.length > 0) ? true : false) || isLoading}
        />
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={(successSaving && successSaving.length > 0) ? true : false }>
            <InputLabel htmlFor="region">Region</InputLabel>
            <Select
              native
              error={region===""}
              value={region}
              onChange={handleSetRegion}
              inputProps={{
                name: "region",
                id: "region"
              }}
              disabled={((successSaving && setSuccessSaving.length > 0) ? true : false) || isLoading}
            >
              <option aria-label="None" value="" />
              {regions.map((t: string, index: number) =>
                <option key={index} value={t}>{t}</option>
              )}
            </Select>
            <FormHelperText>Choose valid region</FormHelperText>
          </FormControl>
        </div>

        {(errorSaving && errorSaving.length > 0) &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorSaving}
          </Alert>
        }
        {(infoSaving && infoSaving.length > 0) &&
          <Alert severity="info">
            <AlertTitle>Information</AlertTitle>
            {infoSaving}
          </Alert>
        }
        {(successSaving && setSuccessSaving.length > 0) &&
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successSaving}
          </Alert>
        }
      </DialogContent>
      {(!successSaving || successSaving.length === 0) &&
        <DialogActions>
          <Button disabled={!tenantValid || isLoading} variant="contained" onClick={handleAddTenant} color="primary">
            Add { isLoading && <CircularProgress size={24} color="inherit" className={classes.buttonProgress}/>}
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(successSaving && successSaving.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default TenantAddDialog;