import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { ChangeModel } from "./change";

export class ChangeListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  changes: Array<ChangeModel> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    console.log(data.data);
    
    if (data && data.data && data.data.GetNode) {
      if (data.data.GetNode.ListChanges && data.data.GetNode.ListChanges.echos) {
        const dt = data.data.GetNode.ListChanges;
        this.lastEvaluatedKey = dt.lastEvaluatedKey;
        this.changes = dt.echos.map((o: any) => {
          const nm = o.new;

          let nmAudit = new AtByModel('', '');
          if (nm) {
            nmAudit = new AtByModel(nm.lastModified.at, nm.lastModified.by);
          }

          const om = o.old;
          let omAudit = new AtByModel('', '');

          if (om) {
            omAudit = new AtByModel(om.lastModified.at, om.lastModified.by);
          }

          const typeName = (nm && nm.__typename) || (om && om.__typename);
          
          const change = new ChangeModel(JSON.stringify(om, null, 2), omAudit, JSON.stringify(nm, null, 2), nmAudit, typeName);
          return change;
        });
      }
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetNode');
    }
  }
}