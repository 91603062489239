import { MountInput, PortInput } from "models/managed/InputTypes"
import { ConfigurableEntity } from "enumerations/configurable-entity"
import { LogLevel } from "enumerations/log-level"
import { UserRole } from "enumerations/user-role"
import { UserStatus } from "enumerations/user-status"
import { IRouteTableEntry } from "interfaces/route-table-entry"
import { TypesStatic } from "./types-static"
import { ApiUserRole } from "enumerations/api-user-role"
import { SignatureAlgorithm } from "enumerations/signature-algorithm"
import { SubscriptionSecurity } from "enumerations/subscription-security"

export class MutationsStatic {
  public static createTenant = /* GraphQL */ `
    mutation CreateTenant($name: String!, $region: String!, $description: String) {
      CreateTenant(name: $name, region: $region, description: $description) {
        name
        region
        description
      }
    }
  `
  public static deleteTenant = /* GraphQL */ `
    query GetTenant($tenant: String!) {
      GetTenant(tenant: $tenant) {
        Delete
      }
    }
  `

  public static updateTenant(description: string) {
    return /* GraphQL */ `
    query GetTenant($tenant: String!) {
      GetTenant(tenant: $tenant) {
        Update(description: ${JSON.stringify(description)}) {
          ${TypesStatic.tenant}
        }
      }
    }
    `
  }


  public static createTenantUser(email: string, role: UserRole) {
    return /* GraphQL */ `
      query GetTenant($tenant: String!) {
        GetTenant(tenant: $tenant) {
          AddUser(email: ${JSON.stringify(email)}, role: ${role}) {
            ${TypesStatic.tenantUser}
          }
        }
      }
    `
  }

  public static deleteTenantUser = /* GraphQL */ `
    query GetTenantUser($tenant: String!, $email: AWSEmail!) {
      GetTenantUser(tenant: $tenant, email: $email) {
        Delete
      }
    }
  `

  public static saveGraphLayout(layout: string, name: string) {
    return /* GraphQL */ `
    query GetTenantUser($tenant: String!, $email: AWSEmail!) {
      GetTenantUser(tenant: $tenant, email: $email) {
        SaveGraphLayout(layout: ${JSON.stringify(layout)}, name: ${JSON.stringify(name)}) {
            layout
            name
          }
        }
      }
    `
  }

  public static updateTenantUser(role: UserRole, status: UserStatus) {
    return /* GraphQL */ `
      query GetTenantUser($tenant: String!, $email: AWSEmail!) {
        GetTenantUser(tenant: $tenant, email: $email) {
            Update(role: ${role}, status: ${status}){
              ${TypesStatic.tenantUser}
            }
          }
        }
    `
  }

  public static createMessageType = /* GraphQL */ `
    mutation CreateMessageType($auditor: String!, $bitmapperTemplate: String!, $name: String!, $tenant: String!, $processorTemplate: String!, $readme: String, $requirements: [String!], $description: String!, $sampleMessage: String!) {
      CreateMessageType(auditor: $auditor, bitmapperTemplate: $bitmapperTemplate, name: $name, tenant: $tenant, processorTemplate: $processorTemplate, readme: $readme, requirements: $requirements, description: $description, sampleMessage: $sampleMessage) {
        ${TypesStatic.messageType}
      }
    }
  `

  public static deleteMessageType = /* GraphQL */ `
    query GetMessageType($name: String!, $tenant: String!) {
      GetMessageType(name: $name, tenant: $tenant) {
        Delete
      }
    }
  `

  public static deleteManagedNodeType = /* GraphQL */ `
    query GetManagedNodeType($name: String!, $tenant: String!){
      GetManagedNodeType(name: $name, tenant: $tenant) {
        Delete
      }
    }
  `

  public static updateMessageType(auditor: string, bitmapperTemplate: string, description: string, readme: string, requirements: string[], processorTemplate: string, sampleMessage: string) {
    return /* GraphQL */ `
      query GetMessageType($name: String!, $tenant: String!) {
        GetMessageType(name: $name, tenant: $tenant) {
          Update(
              auditor: ${JSON.stringify(auditor)}
              bitmapperTemplate: ${JSON.stringify(bitmapperTemplate)}
              description: ${JSON.stringify(description)}
              readme: ${JSON.stringify(readme)}
              requirements: ${JSON.stringify(requirements)}
              processorTemplate: ${JSON.stringify(processorTemplate)}
              sampleMessage: ${JSON.stringify(sampleMessage)}
          ) {
            ${TypesStatic.messageType}
          }
        }
      }
    `
  }

  public static updateManagedNodeType(description: string, imageUri: string, readme: string) {
    return /* GraphQL */ `
      query GetManagedNodeType($name: String!, $tenant: String!){
        GetManagedNodeType(name: $name, tenant: $tenant){
          Update(
            description: ${JSON.stringify(description)}
            imageUri: ${JSON.stringify(imageUri)}
            readme: ${JSON.stringify(readme)}
          ){
            ${TypesStatic.managedNodeType}
          }
        }
      }
    `
  }

  public static createProcessorFunction = /* GraphQL */ `
    mutation CreateProcessorFunction(
      $tenant: String!,
      $name: String!,
      $code: String!,
      $description: String!,
      $argumentMessageType: String!,
      $readme: String,
      $requirements: [String!],
      $returnMessageType: String
    ) {
      CreateProcessorFunction(
        tenant: $tenant,
        name: $name,
        code: $code,
        description: $description,
        argumentMessageType: $argumentMessageType,
        readme: $readme,
        requirements: $requirements,
        returnMessageType: $returnMessageType
      ) {
        ${TypesStatic.processorFunction}
      }
    }
  `

  public static createBitmapperFunction = /* GraphQL */ `
    mutation CreateBitmapperFunction(
      $tenant: String!,
      $name: String!,
      $code: String!,
      $description: String!,
      $argumentMessageType: String!,
      $readme: String,
      $requirements: [String!]
    ) {
      CreateBitmapperFunction(
        tenant: $tenant,
        name: $name,
        code: $code,
        description: $description,
        argumentMessageType: $argumentMessageType,
        readme: $readme,
        requirements: $requirements
      ) {
        ${TypesStatic.bitmapperFunction}
      }
    }
  `

  public static createApiauthenticatorFunction = /* GraphQL */ `
    mutation CreateApiAuthenticatorFunction(
      $tenant: String!,
      $name: String!,
      $code: String!,
      $description: String!,
      $readme: String,
      $requirements: [String!]
    ) {
      CreateApiAuthenticatorFunction(
        tenant: $tenant,
        name: $name,
        code: $code,
        description: $description,
        readme: $readme,
        requirements: $requirements
      ) {
        ${TypesStatic.apiauthenticatorFunction}
      }
    }
  `

  public static deleteFunction = /* GraphQL */ `
    query GetFunction($name: String!, $tenant: String!) {
      GetFunction(name: $name, tenant: $tenant) {
        Delete
      }
    }
  `

  public static updateFunction(code: string, description: string, readme: string, requirements: string[]) {
    return /* GraphQL */ `
    query GetFunction($name: String!, $tenant: String!) 
    {
      GetFunction(name: $name, tenant: $tenant) 
      {
        ... on BitmapperFunction
        { 
          Update(
            code: ${JSON.stringify(code ? code : '')}
            description: ${JSON.stringify(description ? description : '')}
            readme: ${JSON.stringify(readme ? readme : '')}
            requirements: ${JSON.stringify(requirements ? requirements : [])})
          {
            ${TypesStatic.bitmapperFunction}
          }
        }
        ... on ProcessorFunction
        { 
          Update(
            code: ${JSON.stringify(code ? code : '')}
            description: ${JSON.stringify(description ? description : '')}
            readme: ${JSON.stringify(readme ? readme : '')}
            requirements: ${JSON.stringify(requirements ? requirements : [])})
          {
            ${TypesStatic.processorFunction}
          }
        }
        ... on ApiAuthenticatorFunction
        {
          Update(
            code: ${JSON.stringify(code ? code : '')}
            description: ${JSON.stringify(description ? description : '')}
            readme: ${JSON.stringify(readme ? readme : '')}
            requirements: ${JSON.stringify(requirements ? requirements : [])})
          {
            ${TypesStatic.apiauthenticatorFunction}
          }
        }
      }
    }
    `
  }

  public static createApiUser = /* GraphQL */ `
    mutation CreateApiUser($tenant: String!, $role: ApiUserRole!, $description: String) {
      CreateApiUser(tenant: $tenant, role: $role, description: $description) {
        credentials { ${TypesStatic.cognitoCredentials} }
        ${TypesStatic.auditor}
        description
        role
        tenant { ${TypesStatic.tenant} }
        username
        appsyncEndpoint
      }
    }
  `

  public static updateAPIUser(description: string, role: ApiUserRole) {
    return /* GraphQL */ `
      query GetApiUser($tenant: String!, $username: String!) {
        GetApiUser(tenant: $tenant, username: $username) {
          Update(description: ${JSON.stringify(description ? description : '')}, role: ${role} ) {
           ${TypesStatic.apiUser}
          }
        }
      }
    `
  }

  public static deleteApiUser = /* GraphQL */ `
    query GetApiUser($tenant: String!, $username: String!) {
      GetApiUser(tenant: $tenant, username: $username) {
        Delete
      }
    }
  `

  public static createBitmapRouterNode = /* GraphQL */ `
    mutation CreateBitmapRouterNode($tenant: String!,
      $name: String!,
      $receiveMessageType: String!,
      $inlineBitmapper: String,
      $routeTable: AWSJSON,
      $managedBitmapper: String,
      $description: String,
      $config: AWSJSON,
      $requirements: [String!]) {
      CreateBitmapRouterNode(tenant: $tenant,
        name: $name,
        receiveMessageType: $receiveMessageType,
        inlineBitmapper: $inlineBitmapper,
        routeTable: $routeTable,
        managedBitmapper: $managedBitmapper,
        description: $description,
        config: $config,
        requirements: $requirements) {
          ${TypesStatic.bitmapRouterNode}
        }
    }`

  public static updateNode(
    description: string,
    inlineProcessor: string,
    managedProcessor: string,
    requirements: string[],
    sequentialProcessing: boolean) {

    return /* GraphQL */ `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ... on ProcessorNode {
          Update(
            description: ${JSON.stringify(description ? description : '')},
            inlineProcessor: ${JSON.stringify(inlineProcessor ? inlineProcessor : null)},
            managedProcessor: ${JSON.stringify(managedProcessor ? managedProcessor : null)},
            requirements: ${JSON.stringify(requirements ? requirements : [])},
            sequentialProcessing: ${sequentialProcessing}) {
              ${TypesStatic.processorNode}
            }
        }
        ... on CrossTenantSendingNode {
          Update(
            description: ${JSON.stringify(description ? description : '')},
            inlineProcessor: ${JSON.stringify(inlineProcessor ? inlineProcessor : null)},
            managedProcessor: ${JSON.stringify(managedProcessor ? managedProcessor : null)},
            requirements: ${JSON.stringify(requirements ? requirements : [])},
            sequentialProcessing: ${sequentialProcessing}) {
              ${TypesStatic.crossTenantSendingNode}
            }
        }
      }
    }
  `
  }

  public static updateWebhookNode(
    description: string,
    inlineApiAuthenticator: string,
    managedApiAuthenticator: string,
    requirements: string[]) {
    return /* GraphQL */ `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ... on WebhookNode {
          Update(
            description: ${JSON.stringify(description ? description : '')},
            inlineApiAuthenticator: ${JSON.stringify(inlineApiAuthenticator ? inlineApiAuthenticator : null)},
            managedApiAuthenticator: ${JSON.stringify(managedApiAuthenticator ? managedApiAuthenticator : null)},
            requirements: ${JSON.stringify(requirements ? requirements : [])}) 
            {
              ${TypesStatic.webhookNode}
            }
        }
      }
    }
    `
  }

  public static updateLoadBalancerNode(description: string){
    return /* GraphQL */`
    query GetNode($name: String!, $tenant: String!){
      GetNode(name: $name, tenant: $tenant) {
        ... on LoadBalancerNode {
          Update(
            description: ${JSON.stringify(description ? description : '')}
          ){
            ${TypesStatic.loadBalancerNode}
          }
        }
      }
    }
    `
  }

  public static updateExternalNode(description: string) {
    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on ExternalNode {
            Update(
              description: ${JSON.stringify(description)}) {
                ${TypesStatic.externalNode}
            }
          }
        }
      }
    `
  }

  public static getRouteTableString(routeTable: Array<IRouteTableEntry>): string {
    let rt = '"{';
    if (routeTable) {
      for (var x = 0; x < routeTable.length; x++) {
        rt += `\\"${routeTable[x].routeNumber}\\":[`;
        for (var t = 0; t < routeTable[x].toNodes.length; t++) {
          rt += `\\"${routeTable[x].toNodes[t]}\\"`;
          if (t < routeTable[x].toNodes.length - 1) {
            rt += ',';
          }
        }
        rt += ']';
        if (x < routeTable.length - 1) {
          rt += ',';
        }
      };
    }
    rt += '}"';
    return rt;
  }

  public static updateBitmapRouterNodeRouteTable(
    routeTable: Array<IRouteTableEntry>) {

    let rt = MutationsStatic.getRouteTableString(routeTable);

    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on BitmapRouterNode {
            Update(
              routeTable: ${rt}) {
                ${TypesStatic.bitmapRouterNode}
              }
          }
        }
      }
    `
  }

  public static updateBitmapRouterNode(
    description: string,
    inlineBitmapper: string,
    managedBitmapper: string,
    requirements: string[],
    routeTable: Array<IRouteTableEntry>) {

    let rt = MutationsStatic.getRouteTableString(routeTable);

    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on BitmapRouterNode {
            Update(
              description: ${JSON.stringify(description ? description : '')},
              inlineBitmapper: ${JSON.stringify(inlineBitmapper ? inlineBitmapper : null)},
              managedBitmapper: ${JSON.stringify(managedBitmapper ? managedBitmapper : null)},
              requirements: ${JSON.stringify(requirements ? requirements : [])},
              routeTable: ${rt}) {
                ${TypesStatic.bitmapRouterNode}
              }
          }
        }
      }
    `
  }

  public static updateTimerNode(
    description: string) {
      return   /* GraphQL */ `
        query GetNode($name: String!, $tenant: String!) {
          GetNode(name: $name, tenant: $tenant) {
            ... on TimerNode {
              Update(
                description: ${ JSON.stringify(description ? description : '')}
              ) { ${TypesStatic.timerNode} }
            }
          }
        }
      `
    }

  public static updateFilesDotComWebhookNode (description: string, apiKey: string) {
    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on FilesDotComWebhookNode {
            Update(
              description: ${ JSON.stringify(description ? description : '')}, 
              apiKey: ${ JSON.stringify(apiKey ? apiKey : '')}
              ) { ${TypesStatic.FilesDotComWebhookNode}}
          }
        }
      }
    `
  }

  public static createProcessorNode = /* GraphQL */ `
    mutation CreateProcessorNode(
      $tenant: String!,
      $name: String!,
      $receiveMessageType: String!,
      $description: String,
      $config: AWSJSON,
      $sendMessageType: String,
      $inlineProcessor: String,
      $managedProcessor: String,
      $sequentialProcessing: Boolean,
      $requirements: [String!]
    ) {
      CreateProcessorNode(
        tenant: $tenant,
        name: $name,
        receiveMessageType: $receiveMessageType,
        description: $description,
        sendMessageType: $sendMessageType,
        inlineProcessor: $inlineProcessor,
        managedProcessor: $managedProcessor,
        sequentialProcessing: $sequentialProcessing,
        config: $config,
        requirements: $requirements
      ) {
        ${TypesStatic.processorNode}
      }
    }
  `

  public static createExternalNode = /* GraphQL */ `
    mutation CreateExternalNode(
      $app: String!,
      $config: AWSJSON,
      $description: String,
      $name: String!,
      $receiveMessageType: String,
      $sendMessageType: String,
      $tenant: String!
      ) {
        CreateExternalNode(
          app: $app,
          config: $config,
          description: $description,
          name: $name,
          receiveMessageType: $receiveMessageType,
          sendMessageType: $sendMessageType,
          tenant: $tenant
          ) {
            ${TypesStatic.externalNode}
        } 
      }
  `

  public static createWebhookNode = /* GraphQL */ `
    mutation CreateWebhookNode(
      $name: String!,
      $tenant: String!,
      $config: AWSJSON,
      $description: String,
      $inlineApiAuthenticator: String,
      $loggingLevel: LogLevel,
      $managedApiAuthenticator: String,
      $requirements: [String!]
    ) {
      CreateWebhookNode(
        name: $name,
        tenant: $tenant,
        config: $config,
        description: $description,
        inlineApiAuthenticator: $inlineApiAuthenticator,
        loggingLevel: $loggingLevel,
        managedApiAuthenticator: $managedApiAuthenticator,
        requirements: $requirements
      ) {
        ${ TypesStatic.webhookNode}
      }
    }
  `

  public static createWebSubHubNode = /* GraphQL */ `
    mutation CreateWebSubHubNode(
      $name: String!,
      $tenant: String!,
      $description: String,
      $inlineApiAuthenticator: String,
      $managedApiAuthenticator: String,
    ) {
      CreateWebSubHubNode (
        name: $name,
        tenant: $tenant,
        description: $description,
        inlineApiAuthenticator: $inlineApiAuthenticator,
        managedApiAuthenticator: $managedApiAuthenticator
      ) {
        ${ TypesStatic.webSubHubNode }
      }
    }
  `
  public static updateWebSubHubNode(    
    description: string,
    inlineApiAuthenticator: string,
    managedApiAuthenticator: string,
    requirements: string[],
    defaultLeaseSeconds: number,
    deliveryRetries: number,
    maxLeaseSeconds: number,
    signatureAlgorithm: SignatureAlgorithm,
    subscriptionSecurity: SubscriptionSecurity) {
    return /* GraphQL */ `
       query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on WebSubHubNode {
            Update(
              description: ${JSON.stringify(description ? description : "")},
              inlineApiAuthenticator: ${JSON.stringify(inlineApiAuthenticator ? inlineApiAuthenticator : null)},
              managedApiAuthenticator: ${JSON.stringify(managedApiAuthenticator ? managedApiAuthenticator : null)},
              requirements: ${JSON.stringify(requirements ? requirements : [])},
              defaultLeaseSeconds: ${defaultLeaseSeconds},
              deliveryRetries: ${deliveryRetries ? deliveryRetries : 0},
              maxLeaseSeconds: ${maxLeaseSeconds},
              signatureAlgorithm: ${signatureAlgorithm},
              subscriptionSecurity: ${subscriptionSecurity}
            ) { 
                ${TypesStatic.webSubHubNode}
              }
          }
        }
      }
    `
  }

  public static createLoadBalancerNode = /* GraphQL */ `
    mutation CreateLoadBalancerNode(
      $name: String!,
      $tenant: String!,
      $receiveMessageType: String!,
      $description: String
    ) {
      CreateLoadBalancerNode(
        name: $name,
        tenant: $tenant,
        receiveMessageType: $receiveMessageType,
        description: $description
      ) {
        ${TypesStatic.loadBalancerNode}
      }
    }
  `

  public static deleteNode(drain: boolean) {
    return /* GraphQL */ `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on TimerNode {
            Delete(drainEdges: ${drain})
          }
          ... on ProcessorNode {
            Delete(drainEdges: ${drain})
          }
          ... on BitmapRouterNode {
            Delete(drainEdges: ${drain})
          }
          ... on ExternalNode {
            Delete(drainEdges: ${drain})
          }
          ... on FilesDotComWebhookNode {
            Delete(drainEdges: ${drain})
          }
          ... on CrossTenantReceivingNode {
            Delete(drainEdges: ${drain})
          }
          ... on CrossTenantSendingNode {
            Delete(drainEdges: ${drain})
          }
          ... on ManagedNode {
            Delete(drainEdges: ${drain})
          }
          ... on WebhookNode {
            Delete(drainEdges: ${drain})
          }
          ... on LoadBalancerNode {
            Delete(drainEdges: ${drain})
          }
        }
      }
    `
  }

  public static deleteWebSubNode() {
    return /* GraphQL */ `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ... on WebSubSubscriptionNode {
          Delete
        }
        ... on WebSubHubNode {
          Delete
        }
      }
    }
    `
  }

  public static startNode = /* GraphQL */ `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ... on BitmapRouterNode {
          Start
        }
        ... on BitmapRouterNode {
          Start
        }
        ... on ProcessorNode {
          Start
        }
        ... on CrossTenantSendingNode {
          Start
        }
        ... on CrossTenantReceivingNode {
          Start
        }
        ... on ExternalNode {
          Start
        }
        ... on FilesDotComWebhookNode {
          Start
        }
        ... on LoadBalancerNode {
          Start
        }
        ... on ManagedNode {
          Start
        }
        ... on TimerNode {
          Start
        }
        ... on WebhookNode {
          Start
        }
        ... on WebSubHubNode {
          Start
        }
        ... on WebSubSubscriptionNode {
          Start
        }
      }
    }
  `

  public static stopNode = /* GraphQL */ `
  query GetNode($name: String!, $tenant: String!) {
    GetNode(name: $name, tenant: $tenant) {
      ... on BitmapRouterNode {
        Stop
      }
      ... on BitmapRouterNode {
        Stop
      }
      ... on ProcessorNode {
        Stop
      }
      ... on CrossTenantSendingNode {
        Stop
      }
      ... on CrossTenantReceivingNode {
        Stop
      }
      ... on ExternalNode {
        Stop
      }
      ... on FilesDotComWebhookNode {
        Stop
      }
      ... on LoadBalancerNode {
        Stop
      }
      ... on ManagedNode {
        Stop
      }
      ... on TimerNode {
        Stop
      }
      ... on WebhookNode {
        Stop
      }
      ... on WebSubHubNode {
        Stop
      }
      ... on WebSubSubscriptionNode {
        Stop
      }
    }
  }
`

  public static deleteApp(drain: boolean) {
    return /* GraphQL */ `
      query GetApp($name: String!, $tenant: String!) {
        GetApp(name: $name, tenant: $tenant) {
          ... on CrossAccountApp {
            Delete(drainEdges: ${drain})
          }
          ... on CrossTenantReceivingApp {
            Delete(drainEdges: ${drain})
          }
          ... on CrossTenantSendingApp {
            Delete(drainEdges: ${drain})
          }
          ... on ExternalApp {
            Delete(drainEdges: ${drain})
          }
          ... on ManagedApp {
            Delete(drainEdges: ${drain})
          }
        }
      }
    `
  }

  public static deregisterManagedInstance(instanceId: string) {
    return /* GraphQL */ `
      query GetApp($name: String!, $tenant: String!) {
        GetApp(name: $name, tenant: $tenant) {
          ...on ManagedApp {
            DeregisterManagedInstance(instanceId: ${JSON.stringify(instanceId)})
          }
        }
      }
    `
  }

  /**
   * Edge mutations
   */
  public static createEdge =  /* GraphQL */ `
    mutation CreateEdge(
      $tenant: String!,
      $source: String!,
      $target: String!,
      $kmsKey: String,
      $description: String,
      $maxReceiveCount: Int) {
        CreateEdge(
          tenant: $tenant,
          source: $source,
          target: $target,
          kmsKey: $kmsKey,
          description: $description
          maxReceiveCount: $maxReceiveCount) {
            ${TypesStatic.edge}
          }
      }
  `;

  public static deleteEdge(drain: boolean) {
    return /* GraphQL */ `
    query GetEdge($source: String!, $target: String!, $tenant: String!) {
      GetEdge(source: $source, target: $target, tenant: $tenant) {
        Delete(drain: ${drain})
      }
    }
  `;
  }

  public static moveEdge(newSource: string, newTarget: string){
    return /* GraphQL */ `
    query GetEdge($source: String!, $target: String!, $tenant: String!) {
      GetEdge(source: $source, target: $target, tenant: $tenant) {
        Move(source: ${JSON.stringify(newSource)}, target: ${JSON.stringify(newTarget)}){
          ${TypesStatic.edge}
        }
      }
    }
    `;
  }

  public static purgeEdge = /* GraphQL */ `
    query GetEdge($source: String!, $target: String!, $tenant: String!) {
      GetEdge(source: $source, target: $target, tenant: $tenant) {
        Purge
      }
    }
    `;

  public static createManagedApp = /* GraphQL */ `
    mutation CreateManagedApp($tenant: String!, $name: String!, $description: String, $tableAccess: Boolean) {
      CreateManagedApp(tenant: $tenant, name: $name, description: $description, tableAccess: $tableAccess) {
        ${TypesStatic.managedApp}
      }
    }
  `;

  public static createManagedNode = /* GraphQL */ `
  	mutation CreateManagedNode($app: String!, $managedNodeType: String!, $name: String!, $tenant: String!, $description: String, $ports: [PortInput!], $mounts: [MountInput!], $config: AWSJSON) {
      CreateManagedNode(app: $app, managedNodeType: $managedNodeType, name: $name, tenant: $tenant, description: $description, ports: $ports, mounts: $mounts, config: $config) {
        ${TypesStatic.managedNode}
      }
    }
  `

  public static createTimerNode = /* GraphQL */ `
    mutation CreateTimerNode($description: String, $name: String!, $scheduleExpression: String!, $tenant: String!) {
      CreateTimerNode(description: $description, name: $name, scheduleExpression: $scheduleExpression, tenant: $tenant) {
        ${TypesStatic.timerNode}
      }
    }
  `;

  public static createFilesDotComWebhookNode = /* GraphQL */ `
    mutation CreateFilesDotComWebhookNode($apiKey: String!, $name: String!, $tenant: String!, $description: String) {
      CreateFilesDotComWebhookNode(apiKey: $apiKey, name: $name, tenant: $tenant, description: $description) {
        ${TypesStatic.FilesDotComWebhookNode}
      }
    }
  `;

  public static createCrossTenantSendingApp = /* GraphQL */ `
    mutation CreateCrossTenantSendingApp($name: String!, $receivingApp: String!, $receivingTenant: String!, $tenant: String!) {
      CreateCrossTenantSendingApp(name: $name, receivingApp: $receivingApp, receivingTenant: $receivingTenant, tenant: $tenant) {
        ${TypesStatic.crossTenantSendingApp}
      }
    }
  `;

  public static createCrossTenantReceivingApp = /* GraphQL */ `
    mutation CreateCrossTenantReceivingApp($name: String!, $sendingTenant: String!, $tenant: String!) {
      CreateCrossTenantReceivingApp(name: $name, sendingTenant: $sendingTenant, tenant: $tenant) {
        ${TypesStatic.crossTenantReceivingApp}
      }
    }
  `

  public static createCrossTenantSendingNode = /* GraphQL */ `
    mutation CreateCrossTenantSendingNode(
      $app: String!,
      $config: AWSJSON,
      $description: String,
      $inlineProcessor: String,
      $managedProcessor: String,
      $name: String!,
      $receiveMessageType: String!,
      $requirements: [String!],
      $sendMessageType: String,
      $sequentialProcessing: Boolean,
      $tenant: String!) {
      CreateCrossTenantSendingNode(
        app: $app,
        config: $config,
        description: $description,
        inlineProcessor: $inlineProcessor,
        managedProcessor: $managedProcessor,
        name: $name,
        receiveMessageType: $receiveMessageType,
        requirements: $requirements,
        sendMessageType: $sendMessageType,
        sequentialProcessing: $sequentialProcessing,
        tenant: $tenant) {
        ${TypesStatic.crossTenantSendingNode}
      }
    }
  `

  public static createExternalApp = /* GraphQL */ `
    mutation CreateExternalApp($description: String, $name: String!, $tenant: String!, $tableAccess: Boolean) {
      CreateExternalApp(description: $description, name: $name, tenant: $tenant, tableAccess: $tableAccess) {
        ${TypesStatic.externalApp}
      }
    }
  `

  public static createCrossAccountApp = /* GraphQL */ `
    mutation CreateCrossAccountApp($description: String, $name: String!, $tenant: String!, $tableAccess: Boolean, $account: String!) {
      CreateCrossAccountApp(description: $description, name: $name, tenant: $tenant, tableAccess: $tableAccess, account: $account) {
        ${TypesStatic.crossAccountApp}
      }
    }
  `

  public static updateConfig(entity: ConfigurableEntity, config: string) {
    if (entity === ConfigurableEntity.Tenant) {
      return /* GraphQL */ `
        query Get${entity}($name: String!) {
          GetConfig: Get${entity}(tenant: $name) {
            Update(config: ${JSON.stringify(config)}) {
              config
            }
          }
        }
      `
    } else if (entity === ConfigurableEntity.ExternalApp || entity === ConfigurableEntity.ManagedApp || entity === ConfigurableEntity.CrossAccountApp) {
      return /* GraphQL */ `
        query GetApp($name: String!, $tenant: String!) {
          GetConfig: GetApp(name: $name, tenant: $tenant) {
            ... on ExternalApp {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
            ... on ManagedApp {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
            ... on CrossAccountApp {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
          }
        }
      `
    } else if (entity === ConfigurableEntity.BitmapRouterNode || entity === ConfigurableEntity.ProcessorNode || entity === ConfigurableEntity.ExternalNode || entity === ConfigurableEntity.ManagedNode || entity === ConfigurableEntity.WebhookNode) {
      return /* GraphQL */ `
        query GetNode($name: String!, $tenant: String!) {
          GetConfig: GetNode(name: $name, tenant: $tenant) {
            ... on BitmapRouterNode {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
            ... on ProcessorNode {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
            ... on ExternalNode {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
            ... on ManagedNode {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
            ... on WebhookNode {
              Update(config: ${JSON.stringify(config)}) {
                config
              }
            }
          }
        }
      `
    }
    return `
    `
  }

  public static updateManagedNode(description: string, mounts: Array<MountInput>, ports: Array<PortInput>) {

    // remove " around properties, remove " around protocol
    const mts = JSON.stringify(mounts).replace(/"([^"]+)":/g, '$1:');
    const prtsNoDescription = ports.map(o => { return { containerPort: o.containerPort, hostAddress: o.hostAddress, hostPort: o.hostPort, protocol: o.protocol } });

    const prts = JSON.stringify(prtsNoDescription).replace(/"([^"]+)":/g, '$1:').replace('"tcp"', 'tcp').replace('"udp"', 'udp').replace('"sctp"', 'sctp');
    return /* GraphQL */ `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ... on ManagedNode {
          Update(description: ${JSON.stringify(description)}, mounts: ${mts}, ports: ${prts}) {
            ${TypesStatic.managedNode}
          }
        }
      }
    }
    `
  }

  public static getAWSCredentials(duration: number) {
    return /* GraphQL */ `
      query GetApp($name: String!, $tenant: String!) {
        GetApp(name: $name, tenant: $tenant) {
          ... on ExternalApp {
            GetAwsCredentials(duration: ${duration}) {
              accessKeyId
              expiration
              secretAccessKey
              sessionToken
            }
          }
          ... on ManagedApp {
            GetAwsCredentials(duration: ${duration}) {
              accessKeyId
              expiration
              secretAccessKey
              sessionToken
            }
          }
        }
      }
    `
  }

  // Update(config: AWSJSON, description: String, tableAccess: Boolean): ExternalApp!
  public static updateExternalApp(description: string, tableAccess: boolean) {
    return /* GraphQL */ `
      query GetApp($name: String!, $tenant: String!) {
        GetApp(name: $name, tenant: $tenant) {
          ... on ExternalApp {
            Update(description: ${JSON.stringify(description)}, tableAccess: ${tableAccess}) {
              description
              tableAccess
            }
          }
          ... on ManagedApp {
            Update(description: ${JSON.stringify(description)}, tableAccess: ${tableAccess}) {
              description
              tableAccess
            }
          }
          ... on CrossAccountApp {
            Update(description: ${JSON.stringify(description)}, tableAccess: ${tableAccess}) {
              description
              tableAccess
            }
          }
        }
      }
    `
  }

  public static updateCrossTenantSendingApp(description: string) {
    return /* GraphQL */ `
    query GetApp($name: String!, $tenant: String!) {
      GetApp(name: $name, tenant: $tenant) {
        ... on CrossTenantSendingApp {
          Update(description: ${JSON.stringify(description)}) {
            ${TypesStatic.crossTenantSendingApp}
          }
        }
      }
    }
    `
  }

  public static createManagedNodeType = /* GraphQL */ `
    mutation CreateManagedNodeType(
      $description: String!,
      $imageUri: String!,
      $name: String!,
      $tenant: String!,
      $configTemplate: AWSJSON,
      $mountRequirements: [MountRequirementInput!],
      $portRequirements: [PortRequirementInput!],
      $readme: String,
      $receiveMessageType: String,
      $sendMessageType: String
    ) {
      CreateManagedNodeType(
        description: $description,
        imageUri: $imageUri,
        name: $name,
        tenant: $tenant,
        configTemplate: $configTemplate,
        mountRequirements: $mountRequirements,
        portRequirements: $portRequirements,
        readme: $readme,
        receiveMessageType: $receiveMessageType,
        sendMessageType: $sendMessageType
      ) {
        ${TypesStatic.managedNodeType}
      }
    }
  `

  public static deleteLoginUser = /* GraphQL */ `
    query GetUser {
      GetUser{
       Delete
      }
    }
  `
}