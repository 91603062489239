import React, { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import {
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import EchoAppBar from "components/EchoAppBar";
import EchoMenu from "components/EchoMenu";
import TenantContext from "contexts/TenantContext";
import TenantListContext from "contexts/TenantListContext";
import { IMenuRoute } from "interfaces/menu-route";
import routes from "variables/routes";
import "./App.css";
import { Redirect } from "react-router-dom";
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySelectMfaType,
  AmplifySignUp,
  AmplifyTotpSetup,
} from "@aws-amplify/ui-react";
import { Auth } from "@aws-amplify/auth";
import { authService } from "services/common";
import { GraphQLHelper } from "utilities/graphql-helper";
import { QueriesStatic } from "graphql/queries-static";
import { SortTenants, TenantListModel } from "models/tenant/tenant-list-model";
import { ITenant, NoTenant } from "interfaces/tenant";
import { TenantUserEmailContext } from "contexts/TenantUserFunctions";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const formFields = [
  {
    type: "email",
    label: "Email Address",
    required: true,
  },
  {
    type: "password",
    label: "Password",
    required: true,
  },
  {
    type: "given_name",
    label: "First Name",
    required: true,
    placeholder: "Enter your first name",
  },
  {
    type: "family_name",
    label: "Last Name",
    required: true,
    placeholder: "Enter your last name",
  }
];

const MFATypeOptions = {
  SMS: false,
  Optional: false,
  TOTP: true,
};

const AUTO_LOGOUT_THRESHOLD = 15 * 60 * 1000; // 15 minutes in milliseconds

const AuthStateApp: React.FC = (props) => {
  const [authState, setAuthState] = useState<AuthState>();
  const [tenantList, setTenantList] = useState<Array<ITenant>>([]);
  const [tenant, setTenant] = useState<ITenant>(NoTenant);
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState<any>();
  const [userEmail, setUserEmail] = useState<string>('');
  const [lastActivityTime, setLastActivityTime] = useState<number | null>(null);

  const history = useHistory();

  useEffect(() => {
    // Function to handle user activity
    const handleUserActivity = (): void => {
      setLastActivityTime(Date.now());
    };

    // Attach event listeners to detect user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clear event listeners and perform logout when inactive
    const timer = setInterval( async () => {
      if (lastActivityTime && Date.now() - lastActivityTime > AUTO_LOGOUT_THRESHOLD) {
        // logout actions here
        try {
          await Auth.signOut({ global: true, });
          authService.clearData();
          window.location.reload();
          console.log("User has been automatically logged out due to inactivity.", lastActivityTime);
        } catch (error) {
          console.log("error signing out: ", error);
        }

        console.log('User has been automatically logged out.');

        // Clear last activity time
        setLastActivityTime(null);
      }
    }, 5000); // Check every 5 seconds for inactivity

    return () => {
      // Cleanup: remove event listeners and clear interval
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearInterval(timer);
    };
  }, [lastActivityTime]);

  useEffect(() => {
    // nextAuthState: AuthState, data?: object
    return onAuthUIStateChange(
      (nextAuthState: AuthState, authData?: object) => {
        setAuthState(nextAuthState);
        setUser(authData);
        //@ts-ignore
        setUserEmail(authData?.attributes?.email);
      }
    );
  }, []);

  useEffect(() => {
    async function fetchTenants() {
      if (user && user.signInUserSession && user.signInUserSession.idToken) {
        const params = {
          limit: 100,
          startKey: null
        }
        console.log(params);
        const p = await GraphQLHelper.execute<TenantListModel>(QueriesStatic.listTenants, params, TenantListModel);
        console.log(p);
        if (p.error) {
          console.log(p.errorMessage);
        } else {
          const tnts = p.result as TenantListModel;
          const sortedTnts = tnts.tenants.sort(SortTenants);
          setTenantList(sortedTnts);
        }
      } 
    }
    fetchTenants();           
  }, [user]);

  useEffect(() => {
    const signOut = async () => {
      try {
        await Auth.signOut({ global: true, });
        authService.clearData();
        window.location.reload();
      } catch (error) {
        console.log("error signing out: ", error);
      }  
    }
    if (user && user.preferredMFA && user.preferredMFA === "NOMFA") {
      signOut();
    }
  });

  const onMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // toggle menu when clicked
    setShowMenu(!showMenu);
  };

  const onMenuItemSelected = (value: IMenuRoute) => {
    // route
    history.push(value.path);
    setShowMenu(false);
  };

  return authState === AuthState.SignedIn && user && user.preferredMFA !== "NOMFA"? (
    <ThemeProvider theme={theme}>
      <TenantContext.Provider value={{ tenant, setTenant }}>
        <TenantListContext.Provider value={{ tenantList, setTenantList }}>
          <TenantUserEmailContext.Provider value={ userEmail }>
            <ToastContainer />
            <div
              style={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <EchoMenu
                show={showMenu}
                closed={() => {
                  setShowMenu(false);
                }}
                onMenuItemClicked={(route) => {
                  onMenuItemSelected(route);
                }}
              />
              <div style={{ minHeight: "50px", background: "#666" }}>
                <EchoAppBar
                  showMenu={false}
                  onMenuClick={onMenuClick}
                  onMenuItemClicked={(route) => {
                    onMenuItemSelected(route);
                  }}
                  userName={user ? user?.attributes?.email : ""}
                  user={user}
                />
              </div>
              <div style={{ background: "#141414", flex: 1, overflow: "auto" }}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                  <Route exact path="/">
                    <Redirect to="/tenants" />
                  </Route>
                </Switch>
              </div>
            </div>
          </TenantUserEmailContext.Provider>
        </TenantListContext.Provider>
      </TenantContext.Provider>
    </ThemeProvider>
  ) : (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySelectMfaType MFATypes={MFATypeOptions}></AmplifySelectMfaType>
        <AmplifySignUp
          formFields={formFields}
          usernameAlias="email"
          slot="sign-up"
        />
        <AmplifyTotpSetup
          headerText="Set up your authenticator"
          slot="totp-setup"
          user={user}
        ></AmplifyTotpSetup>
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
};

export default AuthStateApp;
