import { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from "@material-ui/icons/Menu";
import { Box } from '@mui/material';
import { useLocation } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Avatar } from "@material-ui/core";
import { Auth } from "@aws-amplify/auth";
import TenantContext from "contexts/TenantContext";
import { IMenuRoute } from "interfaces/menu-route";
import routes from "variables/routes";
import { authService } from "services/common";
import TenantAddDialog from "./tenants/TenantAddDialog";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { GraphQLHelper } from "utilities/graphql-helper";
import { LoginUserModel } from "models/login-user-model";
import { MutationsStatic } from "graphql/mutations-static";
import packageJson from  "../../package.json"


const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  appBar: {
    background: "#303030 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: "1",
  },
  logo: {},
  menuList: {
    display: "flex",
  },
  version: {
    fontSize: "1rem",
    color: "#fff",
    display: "inline-block"
  }
}));

interface Props {
  onMenuClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMenuItemClicked(value: IMenuRoute): void;
  userName: string;
  user: any;
  showMenu: boolean;
}

const EchoAppBar: React.FC<Props> = (props) => {

  const classes = useStyles();
  const location = useLocation();

  const { tenant } = useContext(TenantContext);
  const [currentPath, setCurrentPath] = useState<IMenuRoute | undefined>();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteInput, setDeleteInput] = useState('');
  const [canDelete, setCanDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    console.log("version", packageJson.version)
  }, [])

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    console.log("SIGN OUT");
    setAnchorEl(null);
    try {
      await Auth.signOut({ global: true, });
      authService.clearData();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  // find the current path name from the routes...
  useEffect(() => {
    setCurrentPath(routes.find((o) => o.path === location.pathname));
  }, [location]);

  const onHelpClick = () => {
    window.open('https://docs.echo.stream/docs/tenants', '_blank');
  }

  const handleChange = async (e: any) => {
    setDeleteInput(e.target.value);
    deleteValid(e.target.value);
  }

  const deleteAccount = async () => {
    try {
      setIsLoading(true);
      const params = {};
      const p = await GraphQLHelper.execute<LoginUserModel>(MutationsStatic.deleteLoginUser, params, LoginUserModel, tenant);
      if (!p.error) {
        signOut();
      } else {
        console.log(p.errorMessage);
        setErrorMessage(p.errorMessage);
      }
    } catch(err) {
      setErrorMessage(JSON.stringify(err));
    } finally {
      setIsLoading(true);
    }  
  }

  const deleteValid = (input: string) => {
    if (input === "Delete Account Now") {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  }

  return (
    <div>
      <TenantAddDialog closed={() => { setShowAddDialog(false); }} show={showAddDialog} />
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          {props.showMenu && (
            <IconButton
              onClick={props.onMenuClick}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          <img src="echostream.png" alt="logo" className={classes.logo} />
          <Typography variant="h6" className={classes.title}>
            <Box textAlign="left">
              <p className={classes.version}>
                {packageJson.version ? `(${packageJson.version})` : "(0.0.0)"}
              </p>
              {currentPath ? ` - ${currentPath.name}` : ""}{" "}
              {currentPath &&
                (currentPath.path === "/tenantdetails" ||
                  currentPath.path === "/tenantsettings") &&
                tenant.name !== "DEFAULT_TENANT" &&
                tenant
                ? ` - [${tenant.name}]`
                : ""}
            </Box>
          </Typography>
          {props.userName && (
            <>
              <div>
                <IconButton onClick={() => { setShowAddDialog(true); }}>
                  <AddIcon />
                </IconButton>
                <IconButton onClick={() => { onHelpClick(); }}>
                  <HelpOutlineIcon />
                </IconButton>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar aria-label="recipe" />
                </IconButton>
                {props.userName}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => { setOpenDeleteConfirm(true); }}
                  >
                    Delete Account
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      await signOut();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
          {props.children}
        </Toolbar>
      </AppBar>
      <Dialog open={openDeleteConfirm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Account: {props.userName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning" >Deleting an Account is permanent. All owned tenants must be deleted or ownership must be given to another user.</Alert>
          </DialogContentText>
          <TextField 
            error={!canDelete}
            helperText="Enter 'Delete Account Now' to delete your account."
            variant="outlined"
            required
            autoFocus
            margin="dense"
            id="deleteAccount"
            label="Delete Account"
            fullWidth
            onChange={handleChange}
            disabled={isLoading}
          />
          {(errorMessage && errorMessage.length > 0) &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        }
        </DialogContent>
        <DialogActions>
          <Button disabled={!canDelete} variant="contained" color="primary" onClick={() => { deleteAccount(); }}>
            DELETE
          </Button>
          <Button variant="contained" onClick={() => { setOpenDeleteConfirm(false); setDeleteInput(''); setErrorMessage(''); }} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EchoAppBar;
